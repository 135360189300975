// import React from "react";
import swiperimg from "../../assets/swiperimg.svg";
import swiperimg2 from "../../assets/swiperimg2.svg";
import swiperimg3 from "../../assets/swiperimg3.svg";
import swiperimg4 from "../../assets/swiperimg4.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/scss/pagination";
import "./CollectionSwiper.scss";

function CollectionSwiper() {
  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      autoplay={{
        delay: 0,
        disableOnInteraction: false,
      }}
      loop={true}
      pagination={{ clickable: true }}
      speed={6000}>
      <SwiperSlide>
        <img src={swiperimg} alt='productimage' />
      </SwiperSlide>
      <SwiperSlide>
        <img src={swiperimg2} alt='productimage' />
      </SwiperSlide>
      <SwiperSlide>
        <img src={swiperimg3} alt='productimage' />
      </SwiperSlide>
      <SwiperSlide>
        <img src={swiperimg4} alt='productimage' />
      </SwiperSlide>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='17'
        height='17'
        viewBox='0 0 17 17'
        fill='none'>
        <path
          d='M4.25736 1.92623C6.6005 -0.416911 10.3995 -0.416911 12.7426 1.92623L14.7574 3.94095C17.1005 6.2841 17.1005 10.0831 14.7574 12.4262L12.7426 14.441C10.3995 16.7841 6.6005 16.7841 4.25736 14.441L2.24264 12.4262C-0.100505 10.0831 -0.100505 6.2841 2.24264 3.94095L4.25736 1.92623Z'
          fill='url(#paint0_linear_402_1509)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_402_1509'
            x1='8.5'
            y1='-2.31641'
            x2='8.5'
            y2='18.6836'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#B59859' />
            <stop offset='1' stopColor='#DCBF81' />
          </linearGradient>
        </defs>
      </svg>
      <p className='title'>Formal Collections</p>
    </Swiper>
  );
}

export default CollectionSwiper;
