import React, { useState } from "react";
import "./Filter.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";

function Filter() {
  const [isOpen, setIsOpen] = useState(false);
  const matches = useMediaQuery("(min-width:929px)");

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };

  if (matches) {
    return (
      <div className='Filter'>
        <div className='filter_wrap'>
          <div className='list'>
            <button>See all</button>
            <button>Brands</button>
          </div>
          <div className='list'>
            <Accordion defaultExpanded className='zero_margin'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1-content'
                id='panel1-header'>
                Formal Collections
              </AccordionSummary>
              <AccordionDetails>
                <button>Suits</button>
                <button>Suit Jacket</button>
                <button>Shirts</button>
                <button>Suit Trousers</button>
                <button>Shoes</button>
                <button>Ties</button>
                <button>Accessories</button>
              </AccordionDetails>
            </Accordion>
            <Accordion className='zero_margin'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2-content'
                id='panel2-header'>
                Casual Collections
              </AccordionSummary>
              <AccordionDetails>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
              </AccordionDetails>
            </Accordion>
            <Accordion className='zero_margin'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3-content'
                id='panel3-header'>
                Summer Collections
              </AccordionSummary>
              <AccordionDetails>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
              </AccordionDetails>
            </Accordion>
            <Accordion className='zero_margin'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3-content'
                id='panel3-header'>
                Casual Collections
              </AccordionSummary>
              <AccordionDetails>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
              </AccordionDetails>
            </Accordion>
            <Accordion className='zero_margin'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3-content'
                id='panel3-header'>
                New Collections
              </AccordionSummary>
              <AccordionDetails>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    );
  }
  if (isOpen) {
    return (
      <div className='Mobile_Filter'>
        <p className='filter_by' onClick={() => toggleFilter()}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='13'
            height='23'
            viewBox='0 0 13 23'
            fill='none'>
            <path
              d='M3.08192 11.7105L11.6852 3.10712C11.964 2.82837 12.1066 2.51992 12.1131 2.18177C12.1195 1.84366 11.9769 1.52877 11.6852 1.2371C11.3936 0.945391 11.0819 0.79954 10.7502 0.79954C10.4185 0.79954 10.1012 0.951063 9.79814 1.25411L0.464638 10.5877C0.309742 10.7559 0.193585 10.9329 0.116171 11.1185C0.038723 11.3041 0 11.5047 0 11.7205C0 11.9363 0.038723 12.137 0.116171 12.3226C0.193585 12.5082 0.309742 12.6784 0.464638 12.8333L9.79814 22.1668C10.0883 22.457 10.394 22.5969 10.7154 22.5865C11.0367 22.5762 11.3432 22.4252 11.6349 22.1335C11.9266 21.8418 12.0725 21.5302 12.0725 21.1985C12.0725 20.8668 11.9266 20.5552 11.6349 20.2635L3.08192 11.7105Z'
              fill='white'
              fill-opacity='0.8'
            />
          </svg>
          Filter by
        </p>
        <div className='filter_wrap'>
          <div className='list'>
            <button>See all</button>
            <button>Brands</button>
          </div>
          <div className='list'>
            <Accordion defaultExpanded className='zero_margin'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1-content'
                id='panel1-header'>
                Formal Collections
              </AccordionSummary>
              <AccordionDetails>
                <button>Suits</button>
                <button>Suit Jacket</button>
                <button>Shirts</button>
                <button>Suit Trousers</button>
                <button>Shoes</button>
                <button>Ties</button>
                <button>Accessories</button>
              </AccordionDetails>
            </Accordion>
            <Accordion className='zero_margin'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2-content'
                id='panel2-header'>
                Casual Collections
              </AccordionSummary>
              <AccordionDetails>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
              </AccordionDetails>
            </Accordion>
            <Accordion className='zero_margin'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3-content'
                id='panel3-header'>
                Summer Collections
              </AccordionSummary>
              <AccordionDetails>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
              </AccordionDetails>
            </Accordion>
            <Accordion className='zero_margin'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3-content'
                id='panel3-header'>
                Casual Collections
              </AccordionSummary>
              <AccordionDetails>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
              </AccordionDetails>
            </Accordion>
            <Accordion className='zero_margin'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3-content'
                id='panel3-header'>
                New Collections
              </AccordionSummary>
              <AccordionDetails>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
                <button>Suits</button>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className='apply'>
          <button onClick={() => toggleFilter()}>Apply Filter</button>
        </div>
      </div>
    );
  } else {
    return (
      <button className='filterBttn' onClick={() => toggleFilter()}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='17'
          viewBox='0 0 16 17'
          fill='none'>
          <path
            d='M14.6663 2.69324H1.33301L6.66634 8.9999V13.3599L9.33301 14.6932V8.9999L14.6663 2.69324Z'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        Filter
      </button>
    );
  }
}

export default Filter;
