import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Testimonails.scss";
import "swiper/css";
import { FreeMode, Navigation } from "swiper/modules";
import Pride from "../../assets/Wedding.svg";
import SwiperNavButton from "./SwiperNavButtons";
import useMediaQuery from "@mui/material/useMediaQuery";

function Testimonails() {
  const matches = useMediaQuery("(min-width:728px)");

  const testimonials = [
    // {
    //   id: 1,
    //   name: "Shanae Jamey",
    //   desc: "I am so happy with the service here at Miners. My husband and I just got married this past July and we were so appreciative of the dedicated service they gave us. There was some last minute miscommunication between my husband and I about the colour of his suit and Miners jumped through several hoops just for us a mere week to the date! They let me bring my dress in and match it to a suit. It made me, as a nervous bride feel so much better. The day was naturally perfect, and my husband looked dashing. Highly recommend.",
    // },
    {
      name: "Brendan Larlee",
      desc: "A quaint and charming place to get all your fine clothing needs or desires. Wonderful service and a delightful time discussing styles and fits with Krista, along with just a pleasant stream of conversation the whole way. A mighty fine experience, and I'm looking forward to returning for more great clothes and conversation!",
    },
    {
      name: "Fabian Woods",
      desc: "Absolute amazing experience. I went in not really knowing what I was looking for, and left with an amazing outfit. Customer service was out of this world. I highly recommend, and will be back again.",
    },
    {
      name: "Teri-Lynn Dempsey",
      desc: "Miners Apparel for Men is incomparable to any other men’s store in town!! I have been going there every year for gifts! The store has a great vibe, amazing quality inventory and unique products! This ain’t no mom and pop shop.  Krista’s product knowledge and wicked personality makes it easy to shop here",
    },
    {
      name: "Richard Tomlinson",
      desc: "What an up to date stylish atmosphere to shop for men's clothing in New Glasgow Nova Scotia.  Extremely knowledgeable owner who goes above and beyond to make sure your shopping experience is on point. This is exactly what Pictou county needed and the only place I shop for high quality men's clothing Keep up the awesome work Krista.",
    },
    {
      name: "Lynn Dearsley",
      desc: "Thanks Krista for making shopping for a suit so easy!!! Great store with a great range of pricing and awesome customer service. We will definitely be back.",
    },
    {
      name: "Fabian Woods",
      desc: "Absolute amazing experience. I went in not really knowing what I was looking for, and left with an amazing outfit. Customer service was out of this world. I highly recommend, and will be back again.",
    },
    {
      name: "Josh S",
      desc: "I really wanted to shop locally within the county for my wedding outfit, And Miners was my first stop! I am so very glad I did as the service was great, very hands on, proper service and the prices are more than reasonable! I’d recommend Miners to anyone. Thank you!",
    },
  ];

  return (
    <div className='testimonails_swiper_wrap'>
      <Swiper
        spaceBetween={24}
        slidesPerView={1}
        breakpoints={{
          420: {
            slidesPerView: 1.1,
            spaceBetween: 20,
          },
          680: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          840: {
            slidesPerView: 1.7,
            spaceBetween: 24,
          },
          1040: {
            slidesPerView: 2.1,
            spaceBetween: 24,
          },
        }}
        modules={[FreeMode, Navigation]}
        freeMode={true}
        navigation={true}
        loop={true}>
        <SwiperSlide className='imgslide'>
          <img src={Pride} alt='Pride' />
          <div className='single'>
            <div className='start'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'>
                <path
                  d='M7.5893 1.75439C7.77778 1.17431 8.59844 1.17431 8.78692 1.75439L9.85649 5.04619C10.04 5.61081 10.5661 5.99309 11.1598 5.99309H14.621C15.2309 5.99309 15.4845 6.77359 14.9911 7.1321L12.1909 9.16654C11.7106 9.5155 11.5096 10.134 11.6931 10.6987L12.7627 13.9905C12.9511 14.5705 12.2872 15.0529 11.7938 14.6944L8.99359 12.66C8.5133 12.311 7.86292 12.311 7.38263 12.66L4.58246 14.6944C4.08901 15.0529 3.42508 14.5705 3.61356 13.9905L4.68313 10.6987C4.86658 10.134 4.66561 9.5155 4.18531 9.16654L1.38514 7.1321C0.891693 6.77359 1.14529 5.99309 1.75523 5.99309H5.21643C5.81011 5.99309 6.33627 5.61081 6.51973 5.04619L7.5893 1.75439Z'
                  fill='#C69E44'
                  stroke='#C69E44'
                  strokeWidth='0.740741'
                />
              </svg>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'>
                <path
                  d='M7.5893 1.75439C7.77778 1.17431 8.59844 1.17431 8.78692 1.75439L9.85649 5.04619C10.04 5.61081 10.5661 5.99309 11.1598 5.99309H14.621C15.2309 5.99309 15.4845 6.77359 14.9911 7.1321L12.1909 9.16654C11.7106 9.5155 11.5096 10.134 11.6931 10.6987L12.7627 13.9905C12.9511 14.5705 12.2872 15.0529 11.7938 14.6944L8.99359 12.66C8.5133 12.311 7.86292 12.311 7.38263 12.66L4.58246 14.6944C4.08901 15.0529 3.42508 14.5705 3.61356 13.9905L4.68313 10.6987C4.86658 10.134 4.66561 9.5155 4.18531 9.16654L1.38514 7.1321C0.891693 6.77359 1.14529 5.99309 1.75523 5.99309H5.21643C5.81011 5.99309 6.33627 5.61081 6.51973 5.04619L7.5893 1.75439Z'
                  fill='#C69E44'
                  stroke='#C69E44'
                  strokeWidth='0.740741'
                />
              </svg>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'>
                <path
                  d='M7.5893 1.75439C7.77778 1.17431 8.59844 1.17431 8.78692 1.75439L9.85649 5.04619C10.04 5.61081 10.5661 5.99309 11.1598 5.99309H14.621C15.2309 5.99309 15.4845 6.77359 14.9911 7.1321L12.1909 9.16654C11.7106 9.5155 11.5096 10.134 11.6931 10.6987L12.7627 13.9905C12.9511 14.5705 12.2872 15.0529 11.7938 14.6944L8.99359 12.66C8.5133 12.311 7.86292 12.311 7.38263 12.66L4.58246 14.6944C4.08901 15.0529 3.42508 14.5705 3.61356 13.9905L4.68313 10.6987C4.86658 10.134 4.66561 9.5155 4.18531 9.16654L1.38514 7.1321C0.891693 6.77359 1.14529 5.99309 1.75523 5.99309H5.21643C5.81011 5.99309 6.33627 5.61081 6.51973 5.04619L7.5893 1.75439Z'
                  fill='#C69E44'
                  stroke='#C69E44'
                  strokeWidth='0.740741'
                />
              </svg>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'>
                <path
                  d='M7.5893 1.75439C7.77778 1.17431 8.59844 1.17431 8.78692 1.75439L9.85649 5.04619C10.04 5.61081 10.5661 5.99309 11.1598 5.99309H14.621C15.2309 5.99309 15.4845 6.77359 14.9911 7.1321L12.1909 9.16654C11.7106 9.5155 11.5096 10.134 11.6931 10.6987L12.7627 13.9905C12.9511 14.5705 12.2872 15.0529 11.7938 14.6944L8.99359 12.66C8.5133 12.311 7.86292 12.311 7.38263 12.66L4.58246 14.6944C4.08901 15.0529 3.42508 14.5705 3.61356 13.9905L4.68313 10.6987C4.86658 10.134 4.66561 9.5155 4.18531 9.16654L1.38514 7.1321C0.891693 6.77359 1.14529 5.99309 1.75523 5.99309H5.21643C5.81011 5.99309 6.33627 5.61081 6.51973 5.04619L7.5893 1.75439Z'
                  fill='#C69E44'
                  stroke='#C69E44'
                  strokeWidth='0.740741'
                />
              </svg>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'>
                <path
                  d='M7.5893 1.75439C7.77778 1.17431 8.59844 1.17431 8.78692 1.75439L9.85649 5.04619C10.04 5.61081 10.5661 5.99309 11.1598 5.99309H14.621C15.2309 5.99309 15.4845 6.77359 14.9911 7.1321L12.1909 9.16654C11.7106 9.5155 11.5096 10.134 11.6931 10.6987L12.7627 13.9905C12.9511 14.5705 12.2872 15.0529 11.7938 14.6944L8.99359 12.66C8.5133 12.311 7.86292 12.311 7.38263 12.66L4.58246 14.6944C4.08901 15.0529 3.42508 14.5705 3.61356 13.9905L4.68313 10.6987C4.86658 10.134 4.66561 9.5155 4.18531 9.16654L1.38514 7.1321C0.891693 6.77359 1.14529 5.99309 1.75523 5.99309H5.21643C5.81011 5.99309 6.33627 5.61081 6.51973 5.04619L7.5893 1.75439Z'
                  fill='#C69E44'
                  stroke='#C69E44'
                  strokeWidth='0.740741'
                />
              </svg>
            </div>
            <div className='content'>
              <p className='title'>Shanae Jamey</p>
              <p className='desc'>
                I am so happy with the service here atMiners. My husband and I
                just go married this past July and we were so appreciative of
                the dedicated service they gave us. There was some last minute
                miscommunication between my husband and I about the color of his
                suit and Miners jumped through several hoops just for us a mere
                week to the date! They let me bring my dress in and match it to
                a suit. It made me, as a nervous bride feel so much better. The
                day was naturally perfect, and my husband looked dashing. Highly
                recommend.
              </p>
            </div>
          </div>
        </SwiperSlide>
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className='start'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'>
                <path
                  d='M7.5893 1.75439C7.77778 1.17431 8.59844 1.17431 8.78692 1.75439L9.85649 5.04619C10.04 5.61081 10.5661 5.99309 11.1598 5.99309H14.621C15.2309 5.99309 15.4845 6.77359 14.9911 7.1321L12.1909 9.16654C11.7106 9.5155 11.5096 10.134 11.6931 10.6987L12.7627 13.9905C12.9511 14.5705 12.2872 15.0529 11.7938 14.6944L8.99359 12.66C8.5133 12.311 7.86292 12.311 7.38263 12.66L4.58246 14.6944C4.08901 15.0529 3.42508 14.5705 3.61356 13.9905L4.68313 10.6987C4.86658 10.134 4.66561 9.5155 4.18531 9.16654L1.38514 7.1321C0.891693 6.77359 1.14529 5.99309 1.75523 5.99309H5.21643C5.81011 5.99309 6.33627 5.61081 6.51973 5.04619L7.5893 1.75439Z'
                  fill='#C69E44'
                  stroke='#C69E44'
                  strokeWidth='0.740741'
                />
              </svg>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'>
                <path
                  d='M7.5893 1.75439C7.77778 1.17431 8.59844 1.17431 8.78692 1.75439L9.85649 5.04619C10.04 5.61081 10.5661 5.99309 11.1598 5.99309H14.621C15.2309 5.99309 15.4845 6.77359 14.9911 7.1321L12.1909 9.16654C11.7106 9.5155 11.5096 10.134 11.6931 10.6987L12.7627 13.9905C12.9511 14.5705 12.2872 15.0529 11.7938 14.6944L8.99359 12.66C8.5133 12.311 7.86292 12.311 7.38263 12.66L4.58246 14.6944C4.08901 15.0529 3.42508 14.5705 3.61356 13.9905L4.68313 10.6987C4.86658 10.134 4.66561 9.5155 4.18531 9.16654L1.38514 7.1321C0.891693 6.77359 1.14529 5.99309 1.75523 5.99309H5.21643C5.81011 5.99309 6.33627 5.61081 6.51973 5.04619L7.5893 1.75439Z'
                  fill='#C69E44'
                  stroke='#C69E44'
                  strokeWidth='0.740741'
                />
              </svg>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'>
                <path
                  d='M7.5893 1.75439C7.77778 1.17431 8.59844 1.17431 8.78692 1.75439L9.85649 5.04619C10.04 5.61081 10.5661 5.99309 11.1598 5.99309H14.621C15.2309 5.99309 15.4845 6.77359 14.9911 7.1321L12.1909 9.16654C11.7106 9.5155 11.5096 10.134 11.6931 10.6987L12.7627 13.9905C12.9511 14.5705 12.2872 15.0529 11.7938 14.6944L8.99359 12.66C8.5133 12.311 7.86292 12.311 7.38263 12.66L4.58246 14.6944C4.08901 15.0529 3.42508 14.5705 3.61356 13.9905L4.68313 10.6987C4.86658 10.134 4.66561 9.5155 4.18531 9.16654L1.38514 7.1321C0.891693 6.77359 1.14529 5.99309 1.75523 5.99309H5.21643C5.81011 5.99309 6.33627 5.61081 6.51973 5.04619L7.5893 1.75439Z'
                  fill='#C69E44'
                  stroke='#C69E44'
                  strokeWidth='0.740741'
                />
              </svg>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'>
                <path
                  d='M7.5893 1.75439C7.77778 1.17431 8.59844 1.17431 8.78692 1.75439L9.85649 5.04619C10.04 5.61081 10.5661 5.99309 11.1598 5.99309H14.621C15.2309 5.99309 15.4845 6.77359 14.9911 7.1321L12.1909 9.16654C11.7106 9.5155 11.5096 10.134 11.6931 10.6987L12.7627 13.9905C12.9511 14.5705 12.2872 15.0529 11.7938 14.6944L8.99359 12.66C8.5133 12.311 7.86292 12.311 7.38263 12.66L4.58246 14.6944C4.08901 15.0529 3.42508 14.5705 3.61356 13.9905L4.68313 10.6987C4.86658 10.134 4.66561 9.5155 4.18531 9.16654L1.38514 7.1321C0.891693 6.77359 1.14529 5.99309 1.75523 5.99309H5.21643C5.81011 5.99309 6.33627 5.61081 6.51973 5.04619L7.5893 1.75439Z'
                  fill='#C69E44'
                  stroke='#C69E44'
                  strokeWidth='0.740741'
                />
              </svg>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'>
                <path
                  d='M7.5893 1.75439C7.77778 1.17431 8.59844 1.17431 8.78692 1.75439L9.85649 5.04619C10.04 5.61081 10.5661 5.99309 11.1598 5.99309H14.621C15.2309 5.99309 15.4845 6.77359 14.9911 7.1321L12.1909 9.16654C11.7106 9.5155 11.5096 10.134 11.6931 10.6987L12.7627 13.9905C12.9511 14.5705 12.2872 15.0529 11.7938 14.6944L8.99359 12.66C8.5133 12.311 7.86292 12.311 7.38263 12.66L4.58246 14.6944C4.08901 15.0529 3.42508 14.5705 3.61356 13.9905L4.68313 10.6987C4.86658 10.134 4.66561 9.5155 4.18531 9.16654L1.38514 7.1321C0.891693 6.77359 1.14529 5.99309 1.75523 5.99309H5.21643C5.81011 5.99309 6.33627 5.61081 6.51973 5.04619L7.5893 1.75439Z'
                  fill='#C69E44'
                  stroke='#C69E44'
                  strokeWidth='0.740741'
                />
              </svg>
            </div>
            <div className='content'>
              <p className='title'>{testimonial.name}</p>
              <p className='desc'>{testimonial.desc}</p>
            </div>
          </SwiperSlide>
        ))}
        <div className='buttons'>
          <SwiperNavButton />
        </div>
      </Swiper>
    </div>
  );
}

export default Testimonails;
