import React from "react";
import "./Golden.scss";
import { Link } from "react-router-dom";

function Golden({ content, where, type = "button", disabled, link }) {
  if (where) {
    return (
      <Link className='Golden' to={where}>
        {content}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='17'
          viewBox='0 0 16 17'
          fill='none'>
          <path
            d='M4.66663 11.5169L11.3333 4.85025'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M4.66663 4.85025H11.3333V11.5169'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </Link>
    );
  }

  if (link) {
    return (
      <a
        className='Golden'
        href={link}
        target='_blank'
        rel='noopener noreferrer'>
        {content}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='17'
          viewBox='0 0 16 17'
          fill='none'>
          <path
            d='M4.66663 11.5169L11.3333 4.85025'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M4.66663 4.85025H11.3333V11.5169'
            stroke='white'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </a>
    );
  }

  return (
    <button className='Golden' type={type} disabled={disabled}>
      {content}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='17'
        viewBox='0 0 16 17'
        fill='none'>
        <path
          d='M4.66663 11.5169L11.3333 4.85025'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.66663 4.85025H11.3333V11.5169'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </button>
  );
}

export default Golden;
