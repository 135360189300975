import React from "react";
import { useSwiper } from "swiper/react";

const SwiperNavButton = () => {
  const swiper = useSwiper();
  return (
    <>
      <button onClick={() => swiper.slidePrev()} className='prev'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='30'
          height='30'
          viewBox='0 0 30 30'
          fill='none'>
          <path
            d='M23.3999 14.7127L6.5999 14.7127M6.5999 14.7127L11.3999 9.91269M6.5999 14.7127L11.3999 19.5127'
            stroke='white'
            strokeWidth='1.8'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
      <button onClick={() => swiper.slideNext()} className='next'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='30'
          height='30'
          viewBox='0 0 30 30'
          fill='none'>
          <path
            d='M6.6001 14.7131H23.4001M23.4001 14.7131L18.6001 19.5131M23.4001 14.7131L18.6001 9.91309'
            stroke='white'
            strokeWidth='1.8'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
    </>
  );
};

export default SwiperNavButton;
