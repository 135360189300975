import "./Collection.scss";
import { Link } from "react-router-dom";
import CatalogueCategories from "../../Components/CatalogueCategories/CatalogueCategories";
import DiscountBanner from "../../Components/DiscountBanner/DiscountBanner";
import Filter from "../../Components/Filter/Filter";
import productimage from "../../assets/productimg.svg";
import CollectionSwiper from "../../Components/Swiper/CollectionSwiper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";

function Collection() {
  const matches = useMediaQuery("(max-width:928px)");

  const productlist = [
    {
      name: "Classic Jacket - Cognac",
    },
    {
      name: "another Jacket - Cognac",
    },
    {
      name: "onemore Jacket - Cognac",
    },
    {
      name: "repeated Jacket - Cognac",
    },
    {
      name: "Classic Jacket - tany",
    },
  ];
  const domain = "http://miners-apparel.s3-website-us-east-1.amazonaws.com/";

  return (
    <div className='Collection'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Shop| Miner Apparel for Men</title>
        <meta
          name='description'
          content="Discover a premier selection of wallets, shirts, jeans, and more at Miner Apparel for Men's online store. Enjoy the convenience of home shopping."
        />
        <link rel='canonical' href={`${domain}/shop/:id`} />{" "}
      </Helmet>
      <div className='wrapper'>
        <div className='banner'>
          <div className='wrap'>
            <div className='left'>
              <h1>Formal Collections </h1>
              <p className='desc'>Classy gentleman&apos;s choice</p>
              <div className='navigation'>
                <Link to='/'>Home</Link>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='21'
                  viewBox='0 0 20 21'
                  fill='none'>
                  <path
                    d='M11.1634 10.6836L7.60246 7.12268C7.48707 7.0073 7.42805 6.87963 7.42538 6.73968C7.4227 6.59973 7.48173 6.4694 7.60246 6.34868C7.72319 6.22794 7.85219 6.16757 7.98946 6.16757C8.12674 6.16757 8.2581 6.23029 8.38353 6.35572L12.2467 10.2189C12.3108 10.2885 12.3588 10.3618 12.3909 10.4386C12.4229 10.5154 12.439 10.5984 12.439 10.6878C12.439 10.7771 12.4229 10.8601 12.3909 10.9369C12.3588 11.0138 12.3108 11.0842 12.2467 11.1483L8.38353 15.0115C8.26344 15.1316 8.13689 15.1895 8.00388 15.1852C7.87088 15.1809 7.74402 15.1184 7.6233 14.9977C7.50256 14.877 7.44219 14.748 7.44219 14.6107C7.44219 14.4734 7.50256 14.3444 7.6233 14.2237L11.1634 10.6836Z'
                    fill='white'
                    fillOpacity='0.8'
                  />
                </svg>
                <span>Catalogue</span>
              </div>
            </div>
            <div className='right'>
              <img
                src={productimage}
                alt='productimage'
                className='single_image'
              />
              <div className='swiper_wrapping'>
                <CollectionSwiper />
              </div>
            </div>
          </div>
        </div>
        <div className='shop_products'>
          <div className='wrap'>
            <div className='left'>
              {matches && <p className='section_title'>Formal Collections</p>}
              <Filter />
            </div>
            <div className='right'>
              {productlist.map((product, index) => (
                <div className='product_card' key={index}>
                  <img src={productimage} alt='product image' />
                  <div className='text_box'>
                    <p className='product_name'>{product.name}</p>
                    <a
                      className='cta'
                      href='https://www.google.com/maps/place/Miners+Apparel+for+Men/@45.5875922,-62.6477865,17z/data=!4m6!3m5!1s0x4b5c1f3bef7475c1:0xf3e42e2e11c1281!8m2!3d45.5875885!4d-62.6452116!16s%2Fg%2F11j01gy521?entry=ttu'
                      target='_blank'
                      rel='noopener noreferrer'>
                      Visit In-store
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <DiscountBanner />
        <CatalogueCategories />
      </div>
    </div>
  );
}

export default Collection;
