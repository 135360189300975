import { useState } from "react";
import "./Home.scss";
import Transparent from "../../Components/Buttons/Transparent";
// import Golden from "../../Components/Buttons/Golden";
import LogosSwiper from "../../Components/Swiper/LogosSwiper";
import profile1 from "../../assets/profile1.svg";
import profile2 from "../../assets/profile2.svg";
import profile3 from "../../assets/profile3.svg";
import suits from "../../assets/suits.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Testimonails from "../../Components/Swiper/Testimonails";
import GetInTouch from "../../Components/MapContainer/GetInTouch";
// import Exclusive from "../../Components/ExclusiveOffers/Exclusive";
// import AccessorizeTabs from "../../Components/ProductsTabs/AccessorizeTabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

function Home() {
  const matches = useMediaQuery("(min-width:728px)");
  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [isInView, setIsInView] = useState([]);
  function handleInView(SectionName, InView) {
    if (InView) {
      setIsInView((isInView) => [...isInView, SectionName]);
    }
  }
  const domain = "http://miners-apparel.s3-website-us-east-1.amazonaws.com";
  return (
    <div className='Home'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          Miner Apparel for Men: Premium Suits & Tuxedo Every Occasion
        </title>
        <meta
          name='description'
          content='Suits & tuxedo rentals for prom, weddings, and all formal events. Experience the perfect blend of in-store personalization and online shopping convenience.'
        />
        <link rel='canonical' href={`${domain}/`} />{" "}
      </Helmet>
      <div className='wrapper'>
        <div className='home_header'>
          <div className='wrap'>
            <div className='left'>
              <div className='text_box'>
                <p className='sub_header'>Welcome to Miners Apparel</p>
                <h1 className='header_title'>
                  Suits for Every Man. <br />
                  Style in All Sizes.
                </h1>
                <p className='desc'>— Our Bestsellers Await You</p>
              </div>
              {/* <Golden content='Catalogue' where='/shop' /> */}
            </div>
            <div className='right'>
              <motion.svg
                whileInView={{
                  scale: [1, 2, 2, 1, 1],
                  rotate: [0, 0, 270, 270, 0],
                }}
                transition={{ duration: 1.8, delay: 0.3 }}
                xmlns='http://www.w3.org/2000/svg'
                width='34'
                height='34'
                viewBox='0 0 34 34'
                fill='none'>
                <path
                  d='M12.7574 1.92623C15.1005 -0.416911 18.8995 -0.416911 21.2426 1.92623L31.7574 12.441C34.1005 14.7841 34.1005 18.5831 31.7574 20.9262L21.2426 31.441C18.8995 33.7841 15.1005 33.7841 12.7574 31.441L2.24264 20.9262C-0.100505 18.5831 -0.100505 14.7841 2.24264 12.441L12.7574 1.92623Z'
                  fill='url(#paint0_linear_282_4520)'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_282_4520'
                    x1='17'
                    y1='-2.31641'
                    x2='17'
                    y2='35.6836'
                    gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#B59859' />
                    <stop offset='1' stopColor='#DCBF81' />
                  </linearGradient>
                </defs>
              </motion.svg>
              <p className='sub_header'>Superior Quality</p>
              <p className='desc'>
                Experience the pinnacle of craftsmanship, where every detail
                reflects unmatched excellence.
              </p>
            </div>
          </div>
          <div className='overlay'></div>
        </div>
        <div className='featured catalogueNone'>
          <div className='wrap'>
            <div className='text_box'>
              <p className='sub_header'>Unmissable Savings Ahead</p>
              <p className='heading'>Featured Items</p>
            </div>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              className='collections'>
              <div className='collection_wrap'>
                <div className='collection winter'>
                  <p className='title'>Winter Collections</p>
                  <Transparent content='Explore' where='/shop' />
                  <div className='overlay'></div>
                </div>
                <div className='collection summer'>
                  <p className='title'>Summer Collections</p>
                  <Transparent content='Explore' where='/shop' />
                  <div className='overlay'></div>
                </div>
              </div>
              <div className='collection_wrap'>
                <div className='collection three_in_row formal'>
                  <p className='title'>Formal Collections</p>
                  <Transparent content='Explore' where='/shop' />
                  <div className='overlay'></div>
                </div>
                <div className='collection three_in_row casual'>
                  <p className='title'>Casual Collections</p>
                  <Transparent content='Explore' where='/shop' />
                  <div className='overlay'></div>
                </div>
                <div className='collection three_in_row new'>
                  <p className='title'>New Collections</p>
                  <Transparent content='Explore' where='/shop' />
                  <div className='overlay'></div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
        <InView
          as='div'
          style={{ width: "-webkit-fill-available" }}
          onChange={(inView) => handleInView("featured", inView)}
          triggerOnce>
          <div className='in_store_experience bordertopBreak'>
            <div className='wrap'>
              <motion.div
                initial={{ opacity: 0, x: matches ? -50 : -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
                className='half_black'>
                <p className='sub_header'>Discover Your Perfect Fit</p>
                <p className='heading'>In-Store Experience</p>
                <p className='desc'>
                  Step into our store and immerse yourself in a world of
                  elegance. Our expert stylists are ready to guide you through
                  our exquisite collection, ensuring each suit, shirt, and
                  accessory feels as if it were made just for you.
                </p>
                <Transparent
                  content='Get Directions'
                  link='https://www.google.com/maps/place/Miners+Apparel+for+Men/@45.5875922,-62.6477865,17z/data=!4m6!3m5!1s0x4b5c1f3bef7475c1:0xf3e42e2e11c1281!8m2!3d45.5875885!4d-62.6452116!16s%2Fg%2F11j01gy521?entry=ttu'
                />
              </motion.div>
            </div>
          </div>
          {/* {isInView.includes("featured") && <AccessorizeTabs />} */}
          {isInView.includes("featured") && (
            <div className='in_store_experience second topShadow'>
              <div className='wrap'>
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.3 }}
                  className='half_width'>
                  <p className='sub_header'>Elegance Catalogued</p>
                  <p className='heading'>Elevate Your Wardrobe</p>
                  <p
                    className='desc'
                    style={{ marginTop: "16px", marginBottom: "80px" }}>
                    Each piece is a masterpiece of style and craftsmanship.
                    Browse, select, and revel in the <br /> sophistication that
                    only our select catalogue can offer.
                  </p>
                  {/* <Transparent
                    content='Explore the Catalogue Now'
                    where='/shop'
                  /> */}
                </motion.div>
              </div>
              <div className='overlay'></div>
            </div>
          )}
          {isInView.includes("featured") && (
            <div className='logos'>
              <div className='wrap'>
                <p>
                  Explore our curated collection of <br />
                  suits and tuxedos, featuring only the finest from
                  distinguished brands.
                </p>
                <div className='logos_holder'>
                  <LogosSwiper />
                </div>
              </div>
            </div>
          )}
          {isInView.includes("featured") && (
            <div className='elegance'>
              <div className='wrap'>
                <div className='text_box'>
                  <p className='sub_header'>
                    Premier Rentals - Collins Formal Wear
                  </p>
                  <p className='heading'>Elegance for Every Occasion</p>
                  <p className='desc' style={{ marginBottom: "24px" }}>
                    Each piece is a masterpiece of style and craftsmanship.{" "}
                    <br />
                    Browse, select, and revel in the <br /> sophistication that
                    only our select catalogue can offer.
                  </p>
                  <Transparent
                    content='Suit & Tuxedo Rental'
                    link='https://www.collinsclothiers.com/'
                  />
                </div>
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.3 }}
                  className='images'>
                  <img src={profile1} alt='profile' />
                  <div className='double'>
                    <img src={profile2} alt='profile' />
                    <img src={profile3} alt='profile' />
                  </div>
                </motion.div>
              </div>
            </div>
          )}
        </InView>
        <InView
          as='div'
          style={{ width: "-webkit-fill-available" }}
          onChange={(inView) =>
            handleInView("experience_the_distinction", inView)
          }
          triggerOnce>
          {/* <Exclusive /> */}
          {isInView.includes("experience_the_distinction") && (
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              className='experience_the_distinction'>
              <div className='wrap'>
                <div className='left'>
                  <p className='sub_header'>
                    Why Choose Miners Apparel for Men
                  </p>
                  <p className='heading'>Experience the Distinction</p>
                  <img src={suits} alt='suits' />
                </div>
                <div className='faqs'>
                  <Accordion
                    className='faq'
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}>
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls='panel1-content'
                      id='panel1-header'>
                      In-Store Shopping Experience
                    </AccordionSummary>
                    <AccordionDetails>
                      {" "}
                      Step into our store for an immersive experience. Feel the
                      fabrics, try the fits, and enjoy the process of selecting
                      your perfect garment with expert guidance.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    className='faq'
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}>
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel2" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls='panel1-content'
                      id='panel1-header'>
                      Online Shop Convenience
                    </AccordionSummary>
                    <AccordionDetails>
                      {" "}
                      Browse our carefully curated collections at your leisure
                      through our online shop. It&apos;s the perfect blend of
                      local charm and digital convenience.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    className='faq'
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}>
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel3" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls='panel1-content'
                      id='panel1-header'>
                      Discussion on Styles and Fits
                    </AccordionSummary>
                    <AccordionDetails>
                      Engage in personalized consultations where your
                      preferences meet our expertise. We discuss, understand,
                      and recommend styles and fits that enhance individuality.
                    </AccordionDetails>
                  </Accordion>{" "}
                  <Accordion
                    className='faq'
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}>
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel4" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls='panel1-content'
                      id='panel1-header'>
                      Local Shop Charm
                    </AccordionSummary>
                    <AccordionDetails>
                      As a local establishment, we take pride in our community
                      roots. Experience a warm, welcoming atmosphere where every
                      visit feels like coming home to style.
                    </AccordionDetails>
                  </Accordion>{" "}
                  <Accordion
                    className='faq'
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}>
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel5" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls='panel1-content'
                      id='panel1-header'>
                      Exceptional Customer Service
                    </AccordionSummary>
                    <AccordionDetails>
                      Our dedicated team goes above and beyond to ensure your
                      needs are met with the utmost care and attention.
                      We&apos;re here to guide you through every step of your
                      style journey.
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </motion.div>
          )}
          {isInView.includes("experience_the_distinction") && (
            <div className='prom_night'>
              <div className='wrap'>
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.3 }}
                  className='half_black'>
                  <p className='sub_header'>Reign in Style</p>
                  <p className='heading' style={{ marginBottom: "24px" }}>
                    Be the Highlight of <br />
                    Prom Night
                  </p>
                  {/* <Transparent
                    content='Browse the Collection Now'
                    where='/shop'
                  /> */}
                  <motion.svg
                    whileInView={{
                      scale: [1, 2, 2, 1, 1],
                      rotate: [0, 0, 270, 270, 0],
                    }}
                    transition={{ duration: 1.8, delay: 0.04 }}
                    className='golden_icon'
                    xmlns='http://www.w3.org/2000/svg'
                    width='34'
                    height='34'
                    viewBox='0 0 34 34'
                    fill='none'>
                    <path
                      d='M12.7574 2.24264C15.1005 -0.100505 18.8995 -0.100505 21.2426 2.24264L31.7574 12.7574C34.1005 15.1005 34.1005 18.8995 31.7574 21.2426L21.2426 31.7574C18.8995 34.1005 15.1005 34.1005 12.7574 31.7574L2.24264 21.2426C-0.100505 18.8995 -0.100505 15.1005 2.24264 12.7574L12.7574 2.24264Z'
                      fill='url(#paint0_linear_282_4516)'
                    />
                    <defs>
                      <linearGradient
                        id='paint0_linear_282_4516'
                        x1='17'
                        y1='-2'
                        x2='17'
                        y2='36'
                        gradientUnits='userSpaceOnUse'>
                        <stop stopColor='#B59859' />
                        <stop offset='1' stopColor='#DCBF81' />
                      </linearGradient>
                    </defs>
                  </motion.svg>
                </motion.div>
              </div>
            </div>
          )}
          {isInView.includes("experience_the_distinction") && (
            <div className='testimonails'>
              <div className='wrap'>
                <div className='text_box'>
                  <p className='sub_header'>Satisfied Gentlemen</p>
                  <p className='heading'>Hear from Our Satisfied Gentlemen</p>
                </div>
                <Testimonails />
              </div>
            </div>
          )}
          {isInView.includes("experience_the_distinction") && <GetInTouch />}
        </InView>
      </div>
    </div>
  );
}

export default Home;
