import React, { useEffect, Suspense } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import Home from "./Pages/Home/Home";
import WebFont from "webfontloader";
import Collection from "./Pages/Collection/Collection";
import { CircularProgress } from "@mui/material";

const Terms = React.lazy(() => import("./Pages/Privacy/Terms"));
const Privacy = React.lazy(() => import("./Pages/Privacy/Privacy"));
const Contact = React.lazy(() => import("./Pages/Contact/Contact"));
const About = React.lazy(() => import("./Pages/About/About"));
const NotFound = React.lazy(() => import("./Pages/Notfound/NotFound"));
const Shop = React.lazy(() => import("./Pages/Shop/Shop"));

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Cabinet Grotesk", "Outfit", "Roboto", "DM Sans"],
      },
    });
  }, []);

  return (
    <div className='app'>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route index element={<Home />} />
            <Route
              path='/contact'
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "black",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <Contact />
                </Suspense>
              }
            />
            <Route
              path='/about'
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "black",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <About />
                </Suspense>
              }
            />
            <Route
              path='/privacy'
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "black",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <Privacy />
                </Suspense>
              }
            />
            <Route
              path='/terms'
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "black",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <Terms />
                </Suspense>
              }
            />
            <Route path='shop'>
              <Route
                index
                element={
                  <Suspense
                    fallback={
                      <div
                        style={{
                          width: "100vw",
                          height: "100vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "black",
                        }}>
                        <CircularProgress />
                      </div>
                    }>
                    <Shop />
                  </Suspense>
                }
              />
              <Route path=':id' element={<Collection />} />
            </Route>
            <Route
              path='/*'
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "black",
                      }}>
                      <CircularProgress />
                    </div>
                  }>
                  <NotFound />
                </Suspense>
              }
            />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
