import React from "react";
import "./CatalogueCategories.scss";
import { useNavigate } from "react-router-dom";

function CatalogueCategories({ title }) {
  const navigate = useNavigate();

  return (
    <div className="catalogue_categories">
      <div className="wrap">
        {title && <h2>Catalogue Categories</h2>}
        <div className="category_wrapper">
          <p className="name">Men's Clothing</p>
          <div className="categories_boxes">
            <div
              className="category_box Jackets"
              onClick={() => navigate("/shop/jackets")}>
              <p className="title">Jackets</p>
              <div className="overlay"></div>
            </div>
            <div
              className="category_box Suits"
              onClick={() => navigate("/shop/suits")}>
              <p className="title">Suits</p>
              <div className="overlay"></div>
            </div>
            <div
              className="category_box Shirts"
              onClick={() => navigate("/shop/shirts")}>
              <p className="title">Shirts</p>
              <div className="overlay"></div>
            </div>
            <div
              className="category_box Pants"
              onClick={() => navigate("/shop/pants")}>
              <p className="title">Pants</p>
              <div className="overlay"></div>
            </div>
          </div>
        </div>
        <div className="category_wrapper">
          <p className="name">Accessories</p>
          <div className="categories_boxes">
            <div
              className="category_box Ties"
              onClick={() => navigate("/shop/ties")}>
              <p className="title">Ties</p>
              <div className="overlay"></div>
            </div>
            <div
              className="category_box Accessories"
              onClick={() => navigate("/shop/accessories")}>
              <p className="title">Accessories</p>
              <div className="overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CatalogueCategories;
