import "./GetInTouch.scss";
import { motion } from "framer-motion";

function GetInTouch() {
  return (
    <div className='get_in_touch'>
      <div className='wrap'>
        <div className='text_box'>
          <p className='sub_header'>Get in Touch</p>
          <p className='heading'>Connect With Miners Apparel</p>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          className='boxes'>
          <div className='box'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='48'
              height='49'
              viewBox='0 0 48 49'
              fill='none'>
              <g clipPath='url(#clip0_282_3077)'>
                <path
                  d='M23.9999 46.9982C36.3082 46.9982 46.2857 37.0207 46.2857 24.7125C46.2857 12.4044 36.3082 2.42676 23.9999 2.42676C11.6919 2.42676 1.71423 12.4044 1.71423 24.7125C1.71423 37.0207 11.6919 46.9982 23.9999 46.9982Z'
                  stroke='url(#paint0_linear_282_3077)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M24 16.1406V24.7121L32.7086 34.8606'
                  stroke='url(#paint1_linear_282_3077)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <linearGradient
                  id='paint0_linear_282_3077'
                  x1='23.9999'
                  y1='2.42676'
                  x2='23.9999'
                  y2='46.9982'
                  gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#DEC067' />
                  <stop offset='1' stopColor='#FAEABA' />
                </linearGradient>
                <linearGradient
                  id='paint1_linear_282_3077'
                  x1='28.3543'
                  y1='16.1406'
                  x2='28.3543'
                  y2='34.8606'
                  gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#DEC067' />
                  <stop offset='1' stopColor='#FAEABA' />
                </linearGradient>
                <clipPath id='clip0_282_3077'>
                  <rect
                    width='48'
                    height='48'
                    fill='white'
                    transform='translate(0 0.712891)'
                  />
                </clipPath>
              </defs>
            </svg>
            <p className='title'>Store Hours</p>
            <p className='desc'>Monday to Friday: 10:00 AM - 5:00 PM</p>
          </div>
          <div className='box'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='48'
              height='49'
              viewBox='0 0 48 49'
              fill='none'>
              <g clipPath='url(#clip0_282_3085)'>
                <path
                  d='M22.32 45.524C20.5427 46.6684 18.4271 47.17 16.3251 46.9454C14.2231 46.7209 12.2613 45.7839 10.7658 44.2897L9.4629 42.9868C8.88505 42.4026 8.56091 41.6144 8.56091 40.7925C8.56091 39.9711 8.88505 39.1825 9.4629 38.5982L14.9486 33.1469C15.5269 32.5711 16.3097 32.2478 17.1258 32.2478C17.9418 32.2478 18.7246 32.5711 19.3029 33.1469C19.887 33.7247 20.6755 34.0488 21.4972 34.0488C22.3188 34.0488 23.1073 33.7247 23.6915 33.1469L32.4343 24.404C33.0122 23.8199 33.3364 23.0314 33.3364 22.2097C33.3364 21.3881 33.0122 20.5996 32.4343 20.0154C31.8586 19.4371 31.5353 18.6543 31.5353 17.8383C31.5353 17.0222 31.8586 16.2394 32.4343 15.6611L37.7142 10.1754C38.2984 9.59754 39.087 9.27344 39.9085 9.27344C40.7303 9.27344 41.5189 9.59754 42.1028 10.1754L43.4056 11.4783C44.9265 12.9527 45.8958 14.9041 46.1516 17.0069C46.4077 19.1096 45.9345 21.2365 44.8113 23.0326C38.8329 31.9084 31.1959 39.5456 22.32 45.524Z'
                  stroke='url(#paint0_linear_282_3085)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M23.9999 2.42676C21.0734 2.42676 18.1754 3.00319 15.4716 4.12316C12.7677 5.24312 10.311 6.88469 8.24158 8.95411C6.17213 11.0235 4.5306 13.4803 3.41063 16.1841C2.29067 18.8879 1.71423 21.7859 1.71423 24.7125'
                  stroke='url(#paint1_linear_282_3085)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M23.9999 10.998C20.3627 10.998 16.8744 12.4429 14.3025 15.0149C11.7305 17.5868 10.2856 21.0751 10.2856 24.7123'
                  stroke='url(#paint2_linear_282_3085)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <linearGradient
                  id='paint0_linear_282_3085'
                  x1='27.3902'
                  y1='9.27344'
                  x2='27.3902'
                  y2='46.9978'
                  gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#DEC067' />
                  <stop offset='1' stopColor='#FAEABA' />
                </linearGradient>
                <linearGradient
                  id='paint1_linear_282_3085'
                  x1='12.8571'
                  y1='2.42676'
                  x2='12.8571'
                  y2='24.7125'
                  gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#DEC067' />
                  <stop offset='1' stopColor='#FAEABA' />
                </linearGradient>
                <linearGradient
                  id='paint2_linear_282_3085'
                  x1='17.1428'
                  y1='10.998'
                  x2='17.1428'
                  y2='24.7123'
                  gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#DEC067' />
                  <stop offset='1' stopColor='#FAEABA' />
                </linearGradient>
                <clipPath id='clip0_282_3085'>
                  <rect
                    width='48'
                    height='48'
                    fill='white'
                    transform='translate(0 0.712891)'
                  />
                </clipPath>
              </defs>
            </svg>
            <p className='title'>Call Us</p>
            <p className='desc'>
              <a href='tel:+19028005019'>+1 902-800-5019</a>
            </p>
          </div>
          <div className='box'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='48'
              height='49'
              viewBox='0 0 48 49'
              fill='none'>
              <g clipPath='url(#clip0_282_3095)'>
                <path
                  d='M34.4572 24.7119C34.4572 26.7389 33.858 28.7206 32.7351 30.4082C31.6123 32.0957 30.0159 33.4139 28.1464 34.1972C26.2769 34.9805 24.2177 35.194 22.2273 34.8108C20.2369 34.4276 18.404 33.4649 16.9589 32.0435C15.5139 30.6221 14.521 28.8054 14.105 26.8216C13.6889 24.8378 13.8683 22.7753 14.6206 20.8931C15.3729 19.0109 16.6645 17.3929 18.3333 16.2424C20.0021 15.0919 21.9736 14.46 24.0003 14.4262C25.3654 14.4035 26.7213 14.6527 27.9891 15.1594C29.2569 15.6661 30.4111 16.4202 31.3845 17.3775C32.3578 18.3349 33.1308 19.4766 33.6584 20.7358C34.186 21.995 34.4576 23.3467 34.4572 24.7119Z'
                  stroke='url(#paint0_linear_282_3095)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M34.4568 24.7125V29.1696C34.4568 41.1353 53.2111 29.8553 43.3711 13.6039C41.3898 10.2201 38.5619 7.41015 35.1655 5.45041C31.769 3.4907 27.9211 2.44867 23.9999 2.42676C20.7056 2.43693 17.4545 3.17732 14.4807 4.59464C11.5068 6.01195 8.88422 8.07094 6.80157 10.6234C4.71892 13.1758 3.22803 16.1583 2.43624 19.356C1.64445 22.5537 1.57145 25.8872 2.22248 29.1165C2.87352 32.3459 4.2324 35.3908 6.20129 38.0318C8.17022 40.6732 10.7002 42.8448 13.6091 44.3911C16.518 45.937 19.7336 46.8192 23.0243 46.9735C26.3149 47.1281 29.5989 46.5508 32.6399 45.2839'
                  stroke='url(#paint1_linear_282_3095)'
                  strokeWidth='2.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <linearGradient
                  id='paint0_linear_282_3095'
                  x1='24.1716'
                  y1='14.4248'
                  x2='24.1716'
                  y2='34.9963'
                  gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#DEC067' />
                  <stop offset='1' stopColor='#FAEABA' />
                </linearGradient>
                <linearGradient
                  id='paint1_linear_282_3095'
                  x1='24.0045'
                  y1='2.42676'
                  x2='24.0045'
                  y2='46.998'
                  gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#DEC067' />
                  <stop offset='1' stopColor='#FAEABA' />
                </linearGradient>
                <clipPath id='clip0_282_3095'>
                  <rect
                    width='48'
                    height='48'
                    fill='white'
                    transform='translate(0 0.712891)'
                  />
                </clipPath>
              </defs>
            </svg>
            <p className='title'>E-mail Address</p>
            <p className='desc'>
              <a href='mailto:support@minersclothing.ca'>
                support@minersclothing.ca
              </a>
            </p>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          className='map'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2792.1468718304536!2d-62.65008251020437!3d45.587592150629504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b5c1f3bef7475c1%3A0xf3e42e2e11c1281!2sMiners%20Apparel%20for%20Men!5e0!3m2!1sen!2seg!4v1717225458070!5m2!1sen!2seg'
            allowfullscreen=''
            loading='lazy'></iframe>
          {/* <div className="white_overlay">
            <div className="content">
              <p className="title">Address</p>
              <p className="address">
                145 Provost St, New Glasgow,NS B2H 2P6, Canada
              </p>
            </div>
            <Transparent
              content="Get Direction"
              black={true}
              icon={true}
              link="https://www.google.com/maps/place/Miners+Apparel+for+Men/@45.5875922,-62.6477865,17z/data=!4m6!3m5!1s0x4b5c1f3bef7475c1:0xf3e42e2e11c1281!8m2!3d45.5875885!4d-62.6452116!16s%2Fg%2F11j01gy521?entry=ttu"
            />
          </div> */}
          <div className='overlay'></div>
        </motion.div>
      </div>
    </div>
  );
}

export default GetInTouch;
