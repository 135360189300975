import Golden from "../../Components/Buttons/Golden";
import shirt from "../../assets/Suit Image.svg";
import "./DiscountBanner.scss";
import { motion } from "framer-motion";

function DiscountBanner() {
  return (
    <div className='discount_banner'>
      <div className='wrap'>
        <img src={shirt} alt='shirt with a product' />
        <div className='inner_text_box'>
          <p className='percentage'>30% OFF</p>
          <hr className='divider' />
          <div className='icon_box'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='40'
              height='41'
              viewBox='0 0 40 41'
              fill='none'>
              <path
                d='M26.665 5.71289L19.9984 14.0462M26.665 5.71289H13.3317M26.665 5.71289C27.5491 5.71289 28.3969 6.06408 29.0221 6.6892C29.6472 7.31432 29.9984 8.16217 29.9984 9.04622V12.6662C29.9986 13.5502 30.3499 14.3979 30.975 15.0229L32.355 16.4029C32.9802 17.0279 33.3315 17.8756 33.3317 18.7596V32.3796C33.3317 33.2636 32.9805 34.1115 32.3554 34.7366C31.7303 35.3617 30.8824 35.7129 29.9984 35.7129H23.3317C22.4477 35.7129 21.5998 35.3617 20.9747 34.7366C20.3496 34.1115 19.9984 33.2636 19.9984 32.3796M19.9984 14.0462L13.3317 5.71289M19.9984 14.0462V32.3796M13.3317 5.71289C12.4477 5.71289 11.5998 6.06408 10.9747 6.6892C10.3496 7.31432 9.99837 8.16217 9.99837 9.04622V12.6662C9.99818 13.5502 9.64687 14.3979 9.02171 15.0229L7.64171 16.4029C7.01654 17.0279 6.66523 17.8756 6.66504 18.7596V32.3796C6.66504 33.2636 7.01623 34.1115 7.64135 34.7366C8.26647 35.3617 9.11432 35.7129 9.99837 35.7129H16.665C17.5491 35.7129 18.3969 35.3617 19.0221 34.7366C19.6472 34.1115 19.9984 33.2636 19.9984 32.3796M33.3317 22.3796H28.3317C27.8897 22.3796 27.4658 22.5552 27.1532 22.8677C26.8406 23.1803 26.665 23.6042 26.665 24.0462V27.3796C26.665 27.8216 26.8406 28.2455 27.1532 28.5581C27.4658 28.8706 27.8897 29.0462 28.3317 29.0462H33.3317M6.66504 29.0462H11.665C12.1071 29.0462 12.531 28.8706 12.8435 28.5581C13.1561 28.2455 13.3317 27.8216 13.3317 27.3796V24.0462C13.3317 23.6042 13.1561 23.1803 12.8435 22.8677C12.531 22.5552 12.1071 22.3796 11.665 22.3796H6.66504'
                stroke='white'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>{" "}
            Winter’s Collection
          </div>
          <p className='desc'>Get winter deals today and stay warm in style.</p>
          <div className='options'>
            <p className='title'>Colour</p>
            <div className='color_choices'>
              <button className='color brown'></button>
              <button className='color silver'></button>
              <button className='color black'></button>
            </div>
          </div>
          <div className='options'>
            <p className='title'>Size</p>
            <div className='size_choices'>
              <button className='size'>S</button>
              <button className='size'>M</button>
              <button className='size'>L</button>
              <button className='size'>XL</button>
            </div>
          </div>
          <Golden
            content='Visit In-store'
            link='https://www.google.com/maps/place/Miners+Apparel+for+Men/@45.5875922,-62.6477865,17z/data=!4m6!3m5!1s0x4b5c1f3bef7475c1:0xf3e42e2e11c1281!8m2!3d45.5875885!4d-62.6452116!16s%2Fg%2F11j01gy521?entry=ttu'
          />
        </div>
        <motion.svg
          whileInView={{
            scale: [1, 2, 2, 1, 1],
            rotate: [0, 0, 270, 270, 0],
          }}
          transition={{ duration: 1.8 }}
          className='ice_icon'
          xmlns='http://www.w3.org/2000/svg'
          width='80'
          height='81'
          viewBox='0 0 80 81'
          fill='none'>
          <g opacity='0.2'>
            <path
              d='M33.3314 14.0465L39.998 17.3799L46.6647 14.0465M39.998 7.37988V29.0466L49.998 34.7799M59.7572 21.6066L60.2038 29.0466L66.4238 33.1532M68.8655 24.0466L50.1022 34.8799L50.1355 46.4066M66.4238 48.2732L60.2038 52.3799L59.7572 59.8199M68.8648 57.3799L50.1014 46.5466L40.1348 52.3399M46.6647 67.3799L39.998 64.0466L33.3314 67.3799M39.998 74.0467V52.38L29.998 46.6466M20.2389 59.8199L19.7923 52.3799L13.5723 48.2732M11.1309 57.3799L29.8942 46.5465L29.8609 35.0199M13.5723 33.1532L19.7923 29.0466L20.2389 21.6066M11.1309 24.0466L29.8942 34.8799L39.8609 29.0866'
              stroke='white'
              strokeWidth='6.66667'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
        </motion.svg>
        <p className='percentage_icon'>%</p>
      </div>
    </div>
  );
}

export default DiscountBanner;
