import { useState } from "react";
import "./Navbar.scss";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.webp";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const matches = useMediaQuery("(min-width:728px)");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='Navbar'>
      <div className='wrapper'>
        <Link to='/' className='logo'>
          <img src={Logo} alt='Logo' />
        </Link>
        {matches ? (
          <>
            <div className='menu'>
              <Link to='/'>Home</Link>
              <Link to='/about'>About</Link>
              <Link to='/contact'>Contact</Link>
              {/* <Link to="/shop">Catalogue</Link> */}
            </div>
            <div className='search_box'>
              <input type='text' placeholder='Search' />
              <SearchIcon className='icon' />
            </div>
          </>
        ) : (
          <>
            <div className='mobile_icons'>
              <SearchIcon />
              {isOpen ? (
                <CloseIcon onClick={toggleMenu} />
              ) : (
                <MenuIcon onClick={toggleMenu} />
              )}
              <AnimatePresence>
                {!matches && isOpen && (
                  <motion.div
                    initial={{ left: 100, opacity: 0 }}
                    animate={{ left: 0, opacity: 1 }}
                    exit={{ top: -100, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className='mobile_menu'>
                    <Link to='/' onClick={() => setIsOpen(false)}>
                      Home
                    </Link>
                    <Link to='/about' onClick={() => setIsOpen(false)}>
                      About
                    </Link>
                    <Link to='/contact' onClick={() => setIsOpen(false)}>
                      Contact
                    </Link>
                    {/* <Link to="/shop" onClick={() => setIsOpen(false)}>
                      Catalogue
                    </Link> */}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Navbar;
