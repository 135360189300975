import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./LogosSwiper.scss";
import "swiper/css";
import { Autoplay, FreeMode } from "swiper/modules";
import logos1 from "../../assets/logos1.svg";
import logos2 from "../../assets/logos2.svg";
import logos3 from "../../assets/logos3.svg";
import logos4 from "../../assets/logos4.svg";
import logos5 from "../../assets/logos5.svg";
import logos6 from "../../assets/logos6.svg";
import logos7 from "../../assets/logos7.svg";
import logos8 from "../../assets/logo8.svg";
import logos9 from "../../assets/lolo9.svg";
import logos10 from "../../assets/logo10.svg";
import logos11 from "../../assets/logo11.svg";
import logos12 from "../../assets/logo12.svg";
import logos13 from "../../assets/logo13.svg";
import logos14 from "../../assets/logo14.svg";
import logos15 from "../../assets/logo15.svg";
import logos16 from "../../assets/logo16.svg";

export default () => {
  return (
    <div className="logos_swiper_wrap">
      <Swiper
        modules={[Autoplay, FreeMode]}
        spaceBetween={64}
        slidesPerView={6.1}
        breakpoints={{
          420: {
            slidesPerView: 2.2,
            spaceBetween: 20,
          },
          680: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          840: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1140: {
            slidesPerView: 6,
            spaceBetween: 50,
          },
          1340: {
            slidesPerView: 7.1,
            spaceBetween: 64,
          },
        }}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        loop={true}
        speed={6000}>
        <SwiperSlide>
          <img src={logos1} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos2} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos3} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos4} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos5} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos6} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos7} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos8} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <img src={logos9} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos10} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos11} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos12} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos13} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos14} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos15} alt="logo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logos16} alt="logo" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
