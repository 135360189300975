import React from "react";
import "./Transparent.scss";
import { Link } from "react-router-dom";

function Transparent({ content, where, black, icon, link }) {
  if (where) {
    return (
      <Link
        className={black ? "black transparent_bttn" : "transparent_bttn"}
        to={where}>
        {content}
        {icon && (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='23'
            viewBox='0 0 22 23'
            fill='none'>
            <path
              d='M6.41663 15.7965L15.5833 6.62988'
              stroke='black'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M6.41663 6.62988H15.5833V15.7965'
              stroke='black'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        )}
      </Link>
    );
  }
  return (
    <a
      className={black ? "black transparent_bttn" : "transparent_bttn"}
      href={link}
      target='_blank'
      rel='noopener noreferrer'>
      {content}
      {icon && (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='22'
          height='23'
          viewBox='0 0 22 23'
          fill='none'>
          <path
            d='M6.41663 15.7965L15.5833 6.62988'
            stroke='black'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M6.41663 6.62988H15.5833V15.7965'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    </a>
  );
}

export default Transparent;
